import React from 'react';
import Layout from '../components/layout';

const Join = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{process.env.GATSBY_LANGUAGE === "no" ? "Vil du synes her med din løsning?" : "Vill ni synas här med er lösning?"}</h1>
          <p>
          {process.env.GATSBY_LANGUAGE === "no" ? `Har du en bransjeløsning du vil eksponere på Bransjeløsninger.no, så kan vi hjelpe deg med å legge den opp. Vi kan også hjelpe deg med å utarbeide selgende og søkeordoptimerte tekster, og i tillegg løpende påse at din løsning plasserer seg høyt i søkemotorene.` : `Har ni en lösning som ni vill exponera på Branschlösningar.se?
            Kontakta oss så översänder vi en checklista för ifyllande och sedan
            lägger vi upp er lösning omgående.`}
          </p>
          <p>
            {process.env.GATSBY_LANGUAGE === "no" ? "Kontakt oss hvis du vil synes her:" : "Kontakta:"}
            <br />
            Magnus Larsson
            <br />
            Telefon: 0709-550 450
            <br />
            E-post:{' '}
            <a href="mailto:magnus.larsson@pdcsweden.se">
              magnus.larsson@pdcsweden.se
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Join;